<template>
  <div class="school-filter__content my-3">
    <MainHeader
      small-title-text="map.filter.agreement_title"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Agreement buttons -->
    <ArrayButtonsContent
      :button-array-content="['arrayButtons', 'arrayButtonsText', 'arrayButtonsIcon']"
      :button-array="agreementArray"
      full-width
      :array-text-left-align="arrayButtonsLeftAlign"
      :click-in-clean="clickInClean || selectedAgreement.length === 0"
      @setContentArray="setAgreementFilter"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ArrayButtonsContent from '@/components/molecules/sections/ArrayButtonsContent.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';

export default {
  name: 'AgreementFilterSection',
  components: {
    MainHeader,
    ArrayButtonsContent,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
    arrayButtonsLeftAlign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAgreement: [],
    };
  },
  computed: {
    ...mapGetters({
      agreementsLabels: 'options/agreementsLabels',
      agreementFilter: 'filters/agreementFilter',
    }),
    agreementArray() {
      const agreementArray = this.agreementsLabels.map(({
        agreement_name: name,
        ...rest
      }) => ({
        name,
        ...rest,
      }));
      agreementArray.map((agreement) => {
        Object.assign(agreement, { isApplied: this.agreementFilter?.has(agreement.id) });
        if (agreement.id === 1 || agreement.id === 10 || agreement.id === 11 || agreement.id === 12) {
          Object.assign(agreement, { iconSize: '30' });
        }
        if (agreement.id === 2) {
          Object.assign(agreement, { iconSize: '30' });
        }
        if (
          agreement.id === 3
          || agreement.id === 6
          || agreement.id === 7
          || agreement.id === 8
          || agreement.id === 9
        ) {
          Object.assign(agreement, { iconSize: '30' });
        }
        if (
          agreement.id === 4
        ) {
          Object.assign(agreement, { iconSize: '30' });
        }
        return agreement;
      });
      return agreementArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setAgreementFilter([]);
        }
      },
    },
    agreementFilter: {
      handler(newVal) {
        this.selectedAgreement = [...newVal];
      },
    },
  },
  mounted() {
    this.setAgreementFilter(this.agreementFilter);
  },
  methods: {
    setAgreementFilter(selectedContent) {
      this.selectedAgreement = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
