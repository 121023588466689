<template>
  <section id="filter-div" class="school-filter">
    <HeaderModal v-if="mobile" close-no-margin :content-sections="['close']" @close="$emit('ready')" />
    <div class="school-filter__profile">
      <!-- Audiovisuals -->
      <AudiovisualsFilterSection
        :click-in-clean="clickInClean"
        @setFilter="(content) => stageFilter(FILTER_KEYS.MULTIMEDIA, content)"
      />
      <!-- Admissions -->
      <AdmissionFilterSection
        v-if="TOGGLED_FILTERS.VACANCIES && !hideVacancies"
        :click-in-clean="clickInClean"
        @setFilter="(content) => stageFilter(FILTER_KEYS.HAS_VACANCIES, content)"
      />
      <!-- Distance -->
      <DistanceFilterSection
        :click-in-clean="clickInClean"
        @setFilter="(content) => stageFilter(FILTER_KEYS.DISTANCE, content)"
      />
      <!-- Performance HIDDEN -->
      <PerformanceFilterSection
        v-if="TOGGLED_FILTERS.PERFORMANCE"
        :click-in-clean="clickInClean"
        @setFilter="(content) => stageFilter(FILTER_KEYS.PERFORMANCE, content)"
      />
      <!-- Payment -->
      <PaymentFilterSection
        v-if="TOGGLED_FILTERS.PAYMENT"
        :click-in-clean="clickInClean"
        @setFilter="(content) => stageFilter(FILTER_KEYS.PAYMENT, content)"
      />
      <!-- Agreements -->
      <AgreementsFilterSection
        :click-in-clean="clickInClean"
        array-buttons-left-align
        @setFilter="(content) => stageFilter(FILTER_KEYS.AGREEMENTS, content)"
      />
      <!-- Dependency -->
      <DependencyFilterSection
        :click-in-clean="clickInClean"
        @setFilter="(content) => stageFilter(FILTER_KEYS.DEPENDENCIES, content)"
      />
      <ModalitiesFilterSection
        :click-in-clean="clickInClean"
        array-buttons-left-align
        @setFilter="(content) => stageFilter(FILTER_KEYS.MODALITY, content)"
      />
      <!-- TAGS
        FIXME: We actually don't have the data to match whether a school has a specialty or not. That's why
        we're hiding this filter for now (TOGGLED_FILTERS.SPECIALTIES = false in all tenants) except Chile
        Meanwhile we're gonna use a match with the school's programs to determine if it has a specialty or not.
      -->
      <div class="school-filter__slider">
        <v-expansion-panels height="44px">
          <SpecialtyFilterSection
            v-if="TOGGLED_FILTERS.SPECIALTIES"
            :click-in-clean="clickInClean"
            @setFilter="(content) => stageFilter(FILTER_KEYS.SPECIALTIES, content)"
          />
          <InfrastructureFilterSection
            :click-in-clean="clickInClean"
            @setFilter="(content) => stageFilter(FILTER_KEYS.INFRASTRUCTURE, content)"
          />
          <SportsFilterSection
            :click-in-clean="clickInClean"
            @setFilter="(content) => stageFilter(FILTER_KEYS.SPORTS, content)"
          />
          <ExtracurricularFilterSection
            :click-in-clean="clickInClean"
            @setFilter="(content) => stageFilter(FILTER_KEYS.EXTRACURRICULARS, content)"
          />
          <div class="fill-width" @click="scrollDown()" @keydown.enter="scrollDown()">
            <LanguageFilterSection
              :click-in-clean="clickInClean"
              @setFilter="(content) => stageFilter(FILTER_KEYS.LANGUAGES, content)"
            />
          </div>
        </v-expansion-panels>
        <!-- Disabled for New Haven -->
        <PartnershipFilter
          v-if="TOGGLED_FILTERS.PARTNERSHIPS"
          :click-in-clean="clickInClean"
          @setFilter="(content) => stageFilter(FILTER_KEYS.PARTNERSHIPS, content)"
        />
      </div>
    </div>
    <FilterActionButtons
      style="padding-bottom: 20px"
      :buttons-text="['map.filter.clean', 'map.filter.apply']"
      :active-button-loader="filterLoader"
      @cleanFilters="cleanFilters"
      @applyFilter="commitChanges"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import AdmissionFilterSection from '@/components/organisms/filter/AdmissionFilterSection.vue';
import AgreementsFilterSection from '@/components/organisms/filter/AgreementsFilterSection.vue';
import ModalitiesFilterSection from '@/components/organisms/filter/ModalitiesFilterSection.vue';
import DependencyFilterSection from '@/components/organisms/filter/DependencyFilterSection.vue';
import DistanceFilterSection from '@/components/organisms/filter/DistanceFilterSection.vue';
import ExtracurricularFilterSection from '@/components/organisms/filter/ExtracurricularFilterSection.vue';
import FilterActionButtons from '@/components/organisms/filter/FilterActionButtons.vue';
import InfrastructureFilterSection from '@/components/organisms/filter/InfrastructureFilterSection.vue';
import LanguageFilterSection from '@/components/organisms/filter/LanguageFilterSection.vue';
import PartnershipFilter from '@/components/organisms/filter/PartnershipFilter.vue';
import PaymentFilterSection from '@/components/organisms/filter/PaymentFilterSection.vue';
import PerformanceFilterSection from '@/components/organisms/filter/PerformanceFilterSection.vue';
import SpecialtyFilterSection from '@/components/organisms/filter/SpecialtyFilterSection.vue';
import SportsFilterSection from '@/components/organisms/filter/SportsFilterSection.vue';
import AudiovisualsFilterSection from '@/components/organisms/filter/AudiovisualsFilterSection.vue';

import {
  blankFilterContext,
} from '@/utils/filters';
import {
  TETHER_FILTER_KEYS as FILTER_KEYS,
  TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS,
} from '@/constants/filters';
import trackMixPanel from '@/utils/mixpanel';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

export default {
  name: 'SchoolsFilter',
  components: {
    AdmissionFilterSection,
    AgreementsFilterSection,
    AudiovisualsFilterSection,
    DependencyFilterSection,
    DistanceFilterSection,
    ExtracurricularFilterSection,
    FilterActionButtons,
    HeaderModal,
    InfrastructureFilterSection,
    LanguageFilterSection,
    PartnershipFilter,
    PaymentFilterSection,
    PerformanceFilterSection,
    SpecialtyFilterSection,
    SportsFilterSection,
    ModalitiesFilterSection,
  },
  props: {
    context: {
      type: String,
      default: () => FILTER_CONTEXTS.EXPLORER,
      validation: (value) => Object.values(FILTER_CONTEXTS).includes(value),
    },
  },
  data() {
    return {
      stagedFilters: blankFilterContext(),
      filterLoader: false,
      clickInClean: false,
      FILTER_KEYS,
      TOGGLED_FILTERS: TENANT_CONFIGURATION.SETTINGS.FILTERS,
    };
  },
  computed: {
    ...mapGetters({
      hideVacancies: 'explorer/hideVacancies',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    context: {
      handler(context) {
        this.switchFiltersContext(context);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setContext: 'filters/setContext',
      resetFilters: 'filters/resetContext',
      updateFilteredSchools: 'filters/updateSchools',
      commitFilters: 'filters/commitFilters',
    }),
    switchFiltersContext(context) {
      if (context) {
        this.setContext({ context });
      }
    },
    track(action, identifier) {
      const mixpanelContexts = {
        [FILTER_CONTEXTS.EXPLORER]: 'map',
        [FILTER_CONTEXTS.FAVORITES]: 'favorite',
      };
      const id = identifier ? `_${identifier}` : '';
      trackMixPanel(`${mixpanelContexts[this.context]}_filter_${action}${id}`);
    },
    cleanFilters() {
      this.clickInClean = true;
      this.resetFilters().then(() => {
        this.track('clean');
        this.clickInClean = false;
        this.stagedFilters = blankFilterContext();
      });
    },
    scrollDown() {
      if (!this.isMultimediaOpen) {
        this.isMultimediaOpen = true;
        setTimeout(() => {
          const objDiv = document.getElementById('filter-div');
          objDiv.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }, 200);
      }
      this.isMultimediaOpen = false;
    },
    commitChanges() {
      // TODO: [...this.$t('favorite.filtersList')] tiene los filtros que se muestran en favoritos,
      // se debe hacer un refactor para que se use el mismo array y no tener que hacerlo manualmente en cada tenant
      this.filterLoader = true;
      this.clickInClean = false;
      // timeout for loader render error
      setTimeout(() => {
        this.track('apply');

        this.commitFilters({ filters: this.stagedFilters, updateSchools: false }).then(() => {
          this.updateFilteredSchools({ context: this.context, inFilter: true });
        });

        this.filterLoader = false;
        this.$emit('ready');
      }, 500);
    },
    stageFilter(filterKey, filterValue) {
      // Set-based filter (all the others)
      this.stagedFilters[filterKey] = new Set(filterValue);
      if (filterValue.length > 0) {
        const lastId = filterValue.slice(-1);
        this.track(filterKey, lastId);
      }
    },
  },
};
</script>
