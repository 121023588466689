<template>
  <div
    v-if="buttonArrayContent.includes('arrayButtons')"
    class="d-flex flex-row justify-between"
    :class="{ 'flex-wrap': columns || fullWidth }"
  >
    <button
      v-for="label in buttonArray"
      :key="label.id"
      type="button"
      class="school-filter__profile__div__btn px-4"
      :class="{
        'school-filter__profile__div__btn__stack-contents': stackContents,
        'school-filter__profile__div__btn__selected': selectedContent.includes(label.id),
        'justify-center': centeredArrayButton,
      }"
      :style="`width: ${buttonWidth}`"
      @click="setContentArray(label.id)"
    >
      <SvgIcon
        v-if="buttonArrayContent.includes('arrayButtonsIcon') && label.icon"
        :icon="label.icon"
        :size="label.iconSize"
        :style="narrowColumns ? `width: 100%;` : `width: 20%; height: 100%`"
        :class="{
          'mb-1': narrowColumns,
          'width-auto': textWidthAuto,
        }"
      />
      <PrimaryText
        v-if="buttonArrayContent.includes('arrayButtonsText')"
        :class="{
          'mt-1': columns && !textNoMargin,
          'ml-2': !label.icon || textLeftSafeMargin,
        }"
        :text="label.name"
        small
        :center="columns !== null"
        :font-family="arrayFontFamily"
        :invert-tertiary-color="selectedContent.includes(label.id)"
        :align-left="arrayTextLeftAlign"
        :width-auto="textWidthAuto"
      />
    </button>
  </div>
</template>

<script>
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'ButtonArrayContent',
  components: {
    PrimaryText,
    SvgIcon,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
    buttonArrayContent: {
      type: Array,
      default() {
        return [];
      },
    },
    // id and name object in array
    buttonArray: {
      type: Array,
      default: null,
    },
    arrayFontFamily: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    arrayTextLeftAlign: {
      type: Boolean,
      default: false,
    },
    narrowColumns: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: null,
    },
    centeredArrayButton: {
      type: Boolean,
      default: false,
    },
    textWidthAuto: {
      type: Boolean,
      default: false,
    },
    textLeftSafeMargin: {
      type: Boolean,
      default: false,
    },
    stackContents: {
      type: Boolean,
      default: false,
    },
    textNoMargin: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedContent: [],
    };
  },
  computed: {
    buttonWidth() {
      if (this.fullWidth) {
        return '100%';
      }
      const division = this.columns ? this.columns : this.buttonArray.length;
      return `${Math.floor((100 - 2 * division) / division)}%;`;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.$emit('selectedContentSet', false);
          this.selectedContent = [];
        }
      },
    },
    buttonArray: {
      handler(newVal) {
        if (newVal) {
          this.buttonArray.forEach((btn) => {
            if (btn.isApplied) {
              this.selectedContent.push(btn.id);
            }
          });
        }
      },
    },
  },
  mounted() {
    this.buttonArray.forEach((btn) => {
      if (btn.isApplied) {
        this.selectedContent.push(btn.id);
      }
    });
  },
  methods: {
    setContentArray(labelId) {
      if (!this.selectedContent.includes(labelId)) {
        this.buttonArray.map((btn) => {
          if (btn.name === this.$t('map.filter.vacancies_btn')) {
            Object.assign(btn, { icon: 'white-face.svg' });
          }
          return btn;
        });
        this.$emit('selectedContentSet', true, labelId);
        if (this.singleSelection) {
          this.selectedContent = [labelId];
        } else {
          this.selectedContent.push(labelId);
        }
      } else {
        this.buttonArray.map((btn) => {
          if (btn.name === this.$t('map.filter.vacancies_btn')) {
            Object.assign(btn, { icon: 'face.svg' });
          }
          return btn;
        });
        this.$emit('selectedContentSet', false, labelId);
        let newList = [];
        newList = this.selectedContent.filter((content) => {
          if (content !== labelId) {
            return true;
          }
          return false;
        });
        this.selectedContent = newList;
      }
      this.$emit('setContentArray', this.selectedContent);
    },
  },
};
</script>
