var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.buttonArrayContent.includes('arrayButtons'))?_c('div',{staticClass:"d-flex flex-row justify-between",class:{ 'flex-wrap': _vm.columns || _vm.fullWidth }},_vm._l((_vm.buttonArray),function(label){return _c('button',{key:label.id,staticClass:"school-filter__profile__div__btn px-4",class:{
      'school-filter__profile__div__btn__stack-contents': _vm.stackContents,
      'school-filter__profile__div__btn__selected': _vm.selectedContent.includes(label.id),
      'justify-center': _vm.centeredArrayButton,
    },style:(`width: ${_vm.buttonWidth}`),attrs:{"type":"button"},on:{"click":function($event){return _vm.setContentArray(label.id)}}},[(_vm.buttonArrayContent.includes('arrayButtonsIcon') && label.icon)?_c('SvgIcon',{class:{
        'mb-1': _vm.narrowColumns,
        'width-auto': _vm.textWidthAuto,
      },style:(_vm.narrowColumns ? `width: 100%;` : `width: 20%; height: 100%`),attrs:{"icon":label.icon,"size":label.iconSize}}):_vm._e(),(_vm.buttonArrayContent.includes('arrayButtonsText'))?_c('PrimaryText',{class:{
        'mt-1': _vm.columns && !_vm.textNoMargin,
        'ml-2': !label.icon || _vm.textLeftSafeMargin,
      },attrs:{"text":label.name,"small":"","center":_vm.columns !== null,"font-family":_vm.arrayFontFamily,"invert-tertiary-color":_vm.selectedContent.includes(label.id),"align-left":_vm.arrayTextLeftAlign,"width-auto":_vm.textWidthAuto}}):_vm._e()],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }